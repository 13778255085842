<template>
	<div id="content">
		<div class="divHeader">
			店铺编码：<el-input style="width: 200px" v-model="hdid" placeholder="请输入内容"></el-input>
			&nbsp;&nbsp;&nbsp; 日期：
			<el-date-picker v-model="queryDate" default-time="" type="daterange" range-separator="至"
				start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd">
			</el-date-picker>
			<el-button type="primary" @click="queryClick" style="margin-left: 10px" :icon="icon" size="mini">查询
			</el-button>
		</div>

		<!-- tableTotal.list > 0 -->
		<div class="divHeader" v-if="tableTotal.list > 0">
			总条数：{{ tableTotal.list }}
			<span style="margin-left: 20px">商品总数：{{ tableTotal.countGoods }}</span>
			<span style="margin-left: 20px">总价：￥{{ tableTotal.totalPrice }}</span>

			<div>
				用户名：{{haiDingUserInfo.username}} , 国家：{{countryList[haiDingUserInfo.country]}}
			</div>
		</div>


		<div class="contentDiv">
			<el-table :data="tableData" border style="width: 100%" height="750">
				<el-table-column prop="sku" label="商品编码" width="180" align="center">
				</el-table-column>
				<el-table-column prop="name" label="商品名称" align="center">
				</el-table-column>
				<el-table-column prop="date" label="商品图片" width="180" align="center">
					<template slot-scope="scope">
						<img height="80"
							:src="`http://hwimg.xmvogue.com/thumb/${scope.row.sku}.jpg?x-oss-process=style/440`"
							class="mr-3" alt="..." />
					</template>
				</el-table-column>
				<el-table-column prop="price" label="进价" width="180" align="center">
				</el-table-column>
				<el-table-column prop="number" label="数量" width="180" align="center">
				</el-table-column>
				<el-table-column prop="total" label="金额" align="center" width="180">
				</el-table-column>
				<el-table-column prop="date" label="日期" align="center">
				</el-table-column>
			</el-table>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				icon: "el-icon-search",
				hdid: "",
				queryDate: "",
				tableData: [],
				tableTotal: {
					list: 0,
					countGoods: 0,
					totalPrice: 0,
				},
				countryList: {}, //国家
				haiDingUserInfo :{}
			};
		},
		created() {
			//获取到全部国家
			this.getCountry();
		},
		methods: {
			//获取到全部国家
			getCountry() {
				this.$axios.get("/a1/country").then((res) => {
					let resData = res.data.data;
					this.countryList = {}
					for (let key in resData) {
						this.countryList[resData[key]['code']] = resData[key]['name'];
					}
				})
			},
			queryClick() {
				this.icon = "el-icon-loading";
				if (this.queryDate[0] == "" || this.queryDate[1] == "") {
					this.$message.error("请输入店铺ID及查询日期");
				} else {
					let queryData = {
						begin_time: this.queryDate[0],
						end_time: this.queryDate[1],
						hd_id: parseInt(this.hdid),
					};
					//用户查询
					this.$axios.get("/a1/user/getInfo/"+this.hdid).then((res)=>{
						this.haiDingUserInfo = res.data.data
					})
					
					//物料查询
					this.$axios
						.get("/a1/order/materialQuery", {
							params: queryData,
						})
						.then((res) => {
							this.tableData = res.data.data;
							let listNum = 0;
							let totalPrice = 0;
							let countGoods = 0;
							for (let i = 0; i < this.tableData.length; i++) {
								listNum++;
								totalPrice += parseFloat(this.tableData[i].total);
								countGoods += parseInt(this.tableData[i].number);
							}
							this.icon = "el-icon-search";
							this.tableTotal.list = listNum;
							this.tableTotal.totalPrice = totalPrice.toFixed(2);
							this.tableTotal.countGoods = countGoods;
							
							
						})
						.catch((err) => {
							this.$message.error("查询失败");
						});
				}
			},
		},
	};
</script>

<style>
	.divHeader {
		border-bottom: 1px solid rgb(204, 204, 204);
		padding: 10px;
		color: #cc7832;
		font-size: 13px;
	}

	.contentDiv {
		border: 1px solid rgb(204, 204, 204);
		margin-top: 10px;
		margin: 5px;
	}
</style>
